import React from "react";
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaPhoneAlt,
} from "react-icons/fa";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useTranslation } from 'react-i18next';
import '../i18n'
import Trial from "../Components/Trial";

function Contact() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Navbar />
      <Trial />
      <div className="antialiased">
        <div className="flex w-full min-h-screen justify-center items-center pb-[4rem]">
          <div className="flex flex-col md:flex-row md:space-x-6 md:space-y-0 space-y-6 bg-gray-900 w-full max-w-4xl p-8 sm:p-12 rounded-xl shadow-lg text-white overflow-hidden">
            <div className="flex flex-col space-y-8 justify-between">
              <div>
                <h1 className="font-bold text-4xl tracking-wide">{t('contact.head')}</h1>
                <p className="pt-2 text-white text-sm">
                  {t('contact.text-1')}
                </p>
              </div>
              <div className="inline-flex space-x-2 items-center">
                <FaPhoneAlt className="text-white text-xl" />
                <span>+(1) 682 224 8074</span>
              </div>
              <div className="inline-flex space-x-2 items-center">
                <FaEnvelope className="text-white text-xl" />
                <span>contact@starfireinvest00@gmail.com</span>
              </div>

              <div className="flex space-x-4 text-lg">
                <FaFacebook />
                {/* <FaTwitter/>
              <FaLinkedin/> */}
                <a href='https://www.instagram.com/starfire.invest?igsh=MW9uMmY1ZXJIY3drZQ=='><FaInstagram size={30} className="hover:text-slate-400 cursor-pointer"/></a>
              </div>
            </div>

            <div className="relative">
              <div className="absolute z-0 w-40 h-40 bg-teal-400 rounded-full -right-28 -top-28"></div>
              <div className="absolute z-0 w-40 h-40 bg-teal-400 rounded-full -left-28 -bottom-16"></div>
              <div className="relative z-0 bg-white rounded-xl shadow-lg p-8 text-gray-600 md:w-80">
                <form action="" className="flex flex-col space-y-4">
                  <div>
                    <label className="text-sm ">{t('contact.e-con')}</label>
                  </div>

                  <div>
                    <input
                      type="text"
                      placeholder= {t('contact.name')} 
                      className="ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-teal-300 "
                    />
                  </div>

                  <div>
                    <label className="text-sm ">{t('contact.email')} </label>
                  </div>

                  <div>
                    <input
                      type="email"
                      placeholder={t('contact.e-enter')}
                      className="ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-teal-300 "
                    />
                  </div>

                  <div>
                    <label className="text-sm ">{t('contact.msg')}</label>

                    <textarea
                      placeholder= {t('contact.e-msg')}
                      rows="4"
                      className="ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-teal-300 "
                    ></textarea>
                  </div>

                  <button className="inline-block self-end bg-cyan-700 text-white font-bold rounded-lg px-6 py-2 uppercase text-sm">
                    {t('contact.btn')}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
